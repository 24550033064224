<template>
	<div class="pushable">
		<navbar v-if="!$route.meta.hideNavbar" :count="count" :sidebar="sidebar" :toggleSidebar="toggleSidebar" />
		<sui-sidebar class="thin" v-model:visible="sidebar" dimmed inverted style="overflow: visible !important;">
			<template v-for="i in SIDEBAR_ITEMS">
				<sui-menu-item v-if="!i.items || i.items.length==0" @click="i.onClick()">
					{{ i.text }}
					<sui-label color="red" size="mini" v-if="i.badge && i.badge>0" style="margin-left: 5px;">{{i.badge}}</sui-label>
				</sui-menu-item>
				<sui-dropdown v-if="i.items && i.items.length>0" item :text="i.text">
					<sui-label color="red" size="mini" v-if="i.badge && i.badge>0" style="margin-left: 5px;">{{i.badge}}</sui-label>
					<sui-dropdown-menu>
						<sui-menu-item v-for="j in i.items" @click="j.onClick()">
							{{ j.text }}
							<sui-label color="red" size="mini" v-if="j.badge>0" style="margin-left: 5px;">{{j.badge}}</sui-label>
						</sui-menu-item>
					</sui-dropdown-menu>
				</sui-dropdown>
			</template>
			<!-- <sui-menu-item>
				<sui-icon name="home" />
				Home
			</sui-menu-item>
			<sui-menu-item>
				<sui-icon name="gamepad" />
				Games
			</sui-menu-item> -->
		</sui-sidebar>
		<div class="pusher" style="margin-top: 45px; padding-top: 10px; min-height: calc(100vh - 20px)">
			<router-view />
		</div>
	</div>
</template>

<script>
import { ref, onMounted, provide, inject } from 'vue';
import { useToast } from "vue-toastification";
import Navbar from "@/users/components/Navbar.vue";
import Api from "./api/Api";

export default {
	components: {
		Navbar,
	},
	setup () {
		const count = ref(0);
		const toast = useToast();
		const sidebar = ref(false);
		const SIDEBAR_ITEMS = ref([]);
		
		const getPendingOrders = async () => {
			if(!localStorage.getItem('apiKey')) return false
			try {
				const res = await Api.getPendingClientCount();
				if (!res.error) {
					localStorage.setItem('ordersCount', res.data[0].cuenta);
					count.value = res.data[0].cuenta;
				} else {
					toast.error(res.message_client);
				}
			} catch (err) {
				toast.error('Hubo un error al obtener los pedidos pendientes. (GPO-1)');
			}
		};

		const toggleSidebar = (items)=>{
			SIDEBAR_ITEMS.value = items;
			sidebar.value = !sidebar.value;
		}
		
		provide('count', count);
		provide('sidebar', sidebar);
		provide('getPendingOrders', getPendingOrders);
		provide('toggleSidebar', toggleSidebar);
		
		onMounted(() => {
			document.title = 'Limontaño';
			getPendingOrders();
			if(process.env.VUE_APP_DEBUG!=='true'){
				setInterval(getPendingOrders, 2*60*1000);
			}
		});
		
		return {
			count,
			sidebar,
			SIDEBAR_ITEMS,

			toggleSidebar,
			getPendingOrders
		}
	}
}
</script>

<style scoped>
	.ui.sidebar{
		top: 45px;
	}
</style>
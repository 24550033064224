const isAuthenticatedLogin = async  (to, from ,next) =>{
    if(localStorage.getItem('apiKey') != null) next(from)
    else next()
}






export default isAuthenticatedLogin;
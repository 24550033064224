import { createStore } from "vuex";

export default createStore({

    state:{
        apiKey:localStorage.getItem('apiKey'),
        userPermissions:localStorage.getItem('userPermissions'),
        userId: localStorage.getItem('userId'),
        componentWarehouse: localStorage.getItem('componentWarehouse'),
        cart:[],
        clientCart:[],
        pendingOrders: null,
    },

    
})